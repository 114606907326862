import { useState } from "react";
import { APP_CONSTANTS, CONSIGNMENT_STATUS, formatDate } from "../../configs/Utils";


export const SearchBar = (props: { component: string, onSearch: any }) => {
    const [searchText, setSearchText] = useState<any>('');

    const _onDeleteText = () => {
        setSearchText('');
        props.onSearch(null);
    }

    const handleSearch = (e: any) => {
        if (e.target.value.length > 0) {
            setSearchText(e.target.value);
        } else {
            setSearchText(null);
        }
        props.onSearch(e.target.value);
    }

    return (
        <div className='searchBar relative'>
            <img className="searchIcon" src={APP_CONSTANTS.IMAGE_PATH + 'search.png'} />
            <input type='text' placeholder='Search' value={searchText} onChange={handleSearch} />
            { searchText && <img className="removeSearch" onClick={_onDeleteText} src={APP_CONSTANTS.IMAGE_PATH + 'remove.png'} /> }
        </div>
    );
}


export const SearchResults = (props: { data: any, onHeaderBtnClick: any }) => {
    const _getStatusClass = (status: string) => {
        switch (status) {
            case CONSIGNMENT_STATUS.NEW:
                return 'newCard';
            case CONSIGNMENT_STATUS.ACTIVE:
                return 'activeCard';
            case CONSIGNMENT_STATUS.COMPLETED:
                return 'completedCard';
            default:
                return 'new';
        }
    }
    return (
        <div className='searchContent'>
            {
                props.data.map((result: any, index: number) => (
                    <div key={index} className='searchResult' onClick={() => props.onHeaderBtnClick('consignmentDetails', result)}>
                        <div className="flex justify-content-between" style={{alignItems:'start'}}>
                            <div className="flexColumn">
                                <div className='title'>#{result.id}</div>
                                <div className='subtitle'>Expected Delivery: {result.targetFulfillmentDate ? <span className="date">{formatDate(result.targetFulfillmentDate)}</span> : 'Not updated'}</div>
                                <div className='subtitle'><b>{result.orderQty.amount}</b> <span>{result.orderQty.unit}</span></div>
                            </div>
                            <div className={'status ' + _getStatusClass(result.activityStatus)}>{result.activityStatus}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};