import { useEffect, useState } from 'react';
import { ConsignmentCard } from '../../widgets';
import { useNavigate } from 'react-router-dom';
import './consignmentList.scss';
import { STATUS_DETAILS } from '../../../../configs/Workflow';
import { APP_CONSTANTS } from '../../../../configs/Utils';
import { StatusCard } from '../../../../shared/widgets';

const ConsignmentList = (props: {dataArray: any, status: string, isFilterOn?: boolean}) => {
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [consignments, setConsignments] = useState<any>([]);
    const [filteredConsignments, setFilteredConsignments] = useState<any>([]);
    const [openStatusCard, setOpenStatusCard] = useState<any>('');
    const [stageConsignmentMap, setStageConsignmentMap] = useState<any>(null);
    const [filteredStage, setFilteredStage] = useState<any>(null);
    const [showFilter, setShowFilter] = useState(false);

    const _onFilterClick = () => {
        setShowFilter(!showFilter);
    }
    const _onSelectFilter = (stage: any, e: any) => {
        setFilteredStage(stage);
        if (e) e.stopPropagation();
        else _onFilterClick();
    }

    const onSelectConsignment = (consignment: any) => {
        navigate(`../consignments/${consignment.id}`);
    }
    const onStatusClick = (status: string) => {
        setOpenStatusCard(openStatusCard === status ? null : status);
    }
    const extractStageWiseData = () => {
        let stageWiseDataMap: any = {};
        consignments.forEach((consignment: any) => {
            if (stageWiseDataMap[consignment.status]) {
                stageWiseDataMap[consignment.status].push(consignment);
            } else {
                stageWiseDataMap[consignment.status] = [consignment];
            }
        });
        setStageConsignmentMap(stageWiseDataMap);
    }

    useEffect(() => {
        if (filteredStage) {
            let filteredData = stageConsignmentMap[filteredStage]
            setFilteredConsignments(filteredData);
        } else {
            setFilteredConsignments(consignments);
        }
    }, [filteredStage])

    useEffect(() => {
        setConsignments(props.dataArray);
        setFilteredConsignments(props.dataArray);
    }, [props.dataArray])

    useEffect(() => {
        if (consignments.length > 0 && props.isFilterOn)
            extractStageWiseData();
    }, [consignments])

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [window.innerWidth])


    return (
        <>
        { consignments && 
            <div className='cardList'>
                <div className='cardListHeader'>
                    { consignments && <StatusCard status={props.status} count={consignments.length} entity='consignment' onCardClick = {() => onStatusClick(props.status)}/> }
                </div>
                { props.isFilterOn && <div className='filterContainer relative'>
                    <div className='filterButton' onClick={_onFilterClick}>
                        <img src={APP_CONSTANTS.IMAGE_PATH + 'filter.png'} alt='filter'/>
                        {   filteredStage ? 
                            <div>{STATUS_DETAILS[filteredStage].status} <img src={APP_CONSTANTS.IMAGE_PATH + 'cancel.png'} alt='close' onClick={(e) => _onSelectFilter(null, e)}/></div>
                             : 'Showing all active'
                        }
                    </div>
                    {
                        showFilter && stageConsignmentMap && 
                        <div className='absolute filterOverlay'>
                            <StageFilters allconsignments={consignments} stageConsignmentMap={stageConsignmentMap} filteredStage={filteredStage} onSelectFilter={_onSelectFilter}/>
                        </div>
                    }
                </div>}
                <div className={'cardListInner ' + (props.isFilterOn ? 'filteredContent' : 'filterlessCard')}>
                    {
                        ((screenWidth < 1000 && openStatusCard === props.status) || screenWidth >= 1000) && filteredConsignments && 
                        <>
                        {
                            filteredConsignments.length> 0 && filteredConsignments.map((consignment: any, index: number) => (
                                <div className={'customCard ' + (consignment.delayed ? 'delayedCard': '')} key={index} onClick={() => onSelectConsignment(consignment)}>
                                    <ConsignmentCard data={consignment}/>
                                </div>
                            ))
                        }
                        {
                            filteredConsignments.length === 0 && 
                            <div className='emptyCard'>
                                <div className='emptyMsg'>No consignments!</div>
                            </div>
                        }
                        </>
                    }
                </div>
            </div>
        }
        </>
    );
};

export default ConsignmentList;


const StageFilters = (props: {allconsignments: any, stageConsignmentMap: any, filteredStage: string, onSelectFilter: any}) => {

    const _getPercentage = (stage: string) => {
        return (props.stageConsignmentMap[stage].length/props.allconsignments.length)*100;
    }
    return (
        <div className='stageFilters'>
            <img src={APP_CONSTANTS.IMAGE_PATH + 'cancel.png'} alt='close' className='closeIcon' onClick={() => props.onSelectFilter(null)}/>
            {props.stageConsignmentMap && Object.keys(props.stageConsignmentMap).map((key: string, index: number) => (
                <div className={'stageFilter relative ' + (props.filteredStage === key ? 'activeFilter' : '')} key={index} onClick={() => props.onSelectFilter(key)}>
                    <div className='percentageBar absolute' style={{width: _getPercentage(key) + '%'}}></div>
                    {STATUS_DETAILS[key].status}
                    <span className='floatRight'>{props.stageConsignmentMap[key].length}</span>
                </div>
            ))}
        </div>
    );
}