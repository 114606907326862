import { _get, _patch, _post, _put } from "./API";

const baseURL = process.env.REACT_APP_BASE_URL;
const subpath = 'user-mgmt';
const fetchedDataSet: any = {};


export const UserMngmntAPI = {
  searchBasedUser: async (userType: any) => {
    if (!fetchedDataSet[userType])
      fetchedDataSet[userType] = await _get(`${baseURL}/${subpath}/users/search/${userType}`);
    return fetchedDataSet[userType];
  },

  onboardUser: async (data: any) => {
    return await _post(`${baseURL}/${subpath}/user`, data);
  },

  getUserDetails: async (id: string) => {
    return await _get(`${baseURL}/${subpath}/users/${id}`);
  },

  sendLinkRequest: async (id: any) => {
    return await _patch(`${baseURL}/${subpath}/users/send-link-request`, id);
  },

  pendingLinkRequest: async () => {
    return await _get(`${baseURL}/${subpath}/users/pending-link-requests`);
  },

  approvedLinkRequest: async () => {
    return await _get(`${baseURL}/${subpath}/users/approved-link-requests`);
  },

  acceptLinkRequest: async (data: any) => {
    return await _patch(`${baseURL}/${subpath}/users/accept-link-request`, data);
  },

  signUpExporter: async (data: any) => {
    return await _post(`${baseURL}/${subpath}/user/exporter`, data);
  },

  changePassword : async(data:any)=>{
    return await _patch(`${baseURL}/${subpath}/users/change-password`, data);
  }
}