import { APP_CONSTANTS } from "../../configs/Utils";

export const Loader = () => {
    return (
        <div style={loaderWrap} className='maxHeight absolute d-flex justify-content-center align-items-center'>
            <div className="text-center">
                <img src={APP_CONSTANTS.IMAGE_PATH + 'spinner.gif'} alt='Loading...' />
            </div>
            {/* <div style={loader}>Loading...</div> */}
        </div>
    )
}

const loaderWrap = {
    background: '#fffbfb9e',
    width: '100%',
    height: '100%',
    zIndex: '100'
};