import React from 'react'
import { APP_CONSTANTS } from '../../../../configs/Utils';
import './varietyCard.scss'

const VarietyCard = (props: { variety: any }) => {

  return (
    <div className='treeCardWrap'>
      <div className='treeData'>
        <div className='imgWrap'><img src={APP_CONSTANTS.IMAGE_PATH + 'tree.png'} alt='tree' /></div>
        <div className='metagroup flexColumn'>
          {props.variety.plantedTrees && <div className='meta'>Trees : <b>{props.variety.plantedTrees}</b> </div>}
          <div className='meta'>Variety : <b>{props.variety.itemName}</b></div>
          <div className='meta'>Area : <b>{props.variety.cultivatedArea}{" ACRE"}</b></div>
        </div>
      </div>
    </div>
  );

}

export default VarietyCard