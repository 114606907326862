import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { MANGO_VARIANT, EXPORTER, CROPS, MODE, METHODS_OF_HARVEST, PORT, country, PACKHOUSE } from "./Utils";

export const loginSchema: RJSFSchema = {
    "type": 'object',
    "required": ["uid", "credential",],
    "properties": {
        // roles: {
        //     title: 'Select Roles',
        //     type: 'string',
        // },
        "uid": {
            "title": 'User ID',
            "type": 'string'
        },
        "credential": {
            "title": 'Password',
            "type": 'string'
        },
    },
}

export const uiSchema = {
    "credential": {
        "ui:widget": "passwordwidget"
    },
    "ui:submitButtonOptions": {
        "submitText": "Login",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `submitBtn`
        }
    },
}

export const userIdSchema: RJSFSchema = {
    "type": 'object',
    "required": ["uid"],
    "properties": {
        "uid": {
            "title": 'User ID',
            "type": 'string'
        },
    },
}

export const uiUserIdSchema = {
    "ui:submitButtonOptions": {
        "submitText": "Submit",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `submitBtn`
        }
    },
}

export const resetPwdSchema: RJSFSchema = {
    "type": 'object',
    "required": ["oldPassword","newPassword"],
    "properties": {
        "oldPassword": {
            "title": 'Old Password',
            "type": 'string'
        },
        "newPassword": {
            "title": 'New Password',
            "type": 'string'
        },
    },
}

export const resetPwduiSchema = {
    "oldPassword": {
        "ui:widget": "passwordwidget",
     },
    "newPassword": {
        "ui:widget": "passwordwidget",
    },
    "ui:submitButtonOptions": {
        "submitText": "Submit",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `submitBtn`
        }
    },
}

export const ConsignmentSchema: any = {
    type: 'object',
    required: ['contractParty', 'destinationCountryCode','packhouse', 'transportMode', 'contractCreationDate', 'targetFulfillmentDate'],
    // required: ['contractParty', 'destinationCountryCode', 'transportMode', 'originPort', 'destinationPort', 'contractCreationDate', 'targetFulfillmentDate', "description"],
    // required: ['exporter', 'item', 'orderQty','transportMode', 'contractCreationDate', 'targetFulfillmentDate'],
    properties: {
        // exporter: { title: 'Select the exporter', type: 'string' },
        // trader: { title: 'Select the trader', type: 'string' },
        contractParty: { title: 'Select Trader/Exporter', type: 'string' },
        item: {
            type: "object",
            title: "Crop",
            description: 'Crop details',
            properties: {
                "itemType": { type: "string", title: "Select Crop" },
                "itemId": { title: 'Select Variant', type: 'string' }
            },
            required: ["itemType","itemId"]
        },
        orderQty: {
            type: 'object', title: 'Quantity',
            properties: {
                "amount": {
                    "type": "number",
                    "title": "Value"
                },
                "unit": {
                    type: "string",
                    title: 'Unit',
                    default: 'TONNE'
                }
            },
            required: ["amount", "unit"]
        },
        packhouse: { title: 'Select Packhouse', type: 'string' },
        transportMode: { type: 'string', title: 'Mode of Export', oneOf: MODE },
        destinationCountryCode: { type: 'string', title: 'Country of Export', oneOf: [] },
        originPort: { type: 'string', title: 'Origin Port Name' },
        destinationPort: { type: 'string', title: 'Destination Port Name' },
        contractCreationDate: { type: 'string', title: 'Contract Date' },
        targetFulfillmentDate: { type: 'string', title: 'Delivery Date' },
    }
};


export const ConsignmentUiSchema: any = {
    // "trader": {
    //     "ui:widget": "autocomplete",
    //     "ui:options": []
    // },
    // "exporter": {
    //     "ui:widget": "autocomplete",
    //     "ui:options": []
    // },
    "packhouse": {
        "ui:widget": "autocomplete",
        "ui:options": PACKHOUSE,
    },
    "contractParty": {
        "ui:widget": "autocomplete",
        "ui:options": [],
    },
    "orderQty": {
        "amount": {
            "ui:widget": "text"
        }
    },
    "item": {
        "itemType": {
            "ui:widget": "autocomplete",
            "ui:options": CROPS
        },
        "itemId": {
            "ui:widget": "autocomplete",
            "ui:options": [],//MANGO_VARIANT
        },
    },
    // "description": {
    //     "ui:widget": "textarea",
    //     'ui:options': {
    //         rows: 1
    //     }
    // },
    // "transportMode": {
    //     "ui:widget": "autocomplete",
    //     "ui:options": MODE,
    // },
    // "destinationCountryCode": {
    //     "ui:widget": "select",
    //     "ui:options": []
    // },
    // "originPort": {
    //     "ui:widget": "autocomplete",
    //     "ui:options": PORT,
    // },
    // "destinationPort": {
    //     "ui:widget": "autocomplete",
    //     "ui:options": [],
    // },
    "contractCreationDate": {
        "ui:widget": "datepicker"
    },
    "targetFulfillmentDate": {
        "ui:widget": "datepicker"
    },
    "ui:submitButtonOptions": {
        "submitText": "Create Now",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `primaryBtn`
        }
    },

}

export const PrePackhouseSchema: RJSFSchema = {
    type: 'object',
    required: ["desapping", "desappingDateTime", "precooling", "precoolingDateTime", "evidence"],
    properties: {
        // "qty": { title: 'Received Quantity', type: 'integer' },
        "desapping": {
            type: 'boolean', title: 'Desapping done?', "oneOf": [
                {
                    "title": "YES",
                    "const": true
                },
                {
                    "title": "NO",
                    "const": false
                }
            ]
        },
        "desappingDateTime": { title: 'Desapping date & time', type: 'string', format: 'date-time' },
        "precooling": {
            type: 'boolean', title: 'Precooling done?', "oneOf": [
                {
                    "title": "YES",
                    "const": true
                },
                {
                    "title": "NO",
                    "const": false
                }
            ]
        },
        "precoolingDateTime": { title: 'Precooling date & time', type: 'string', format: 'date-time' },
        // "evidence": {
        //     "type": "array",
        //     "title": "Evidence",
        //     "items": { "format": "data-url", "type": 'string' }
        // },
    },
};


export const PrePackhouseUiSchema = {
    "desapping": {
        "ui:widget": "radio",
        "ui:options": {
            "inline": true
        }
    },
    "precooling": {
        "ui:widget": "radio",
        "ui:options": {
            "inline": true
        }
    },
    "ui:submitButtonOptions": {
        "submitText": "Submit",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `submitBtn`
        }
    },

}

export const PortSchema: RJSFSchema = {
    "type": "object",
    "properties": {
        "temp": {
            "type": "boolean",
            "title": "Temperature Maintained?",
            "oneOf": [
                {
                    "title": "YES",
                    "const": true
                },
                {
                    "title": "NO",
                    "const": false
                }
            ]
        },
        "humidity": {
            "type": "boolean",
            "title": "Humidity Maintained?",
            "oneOf": [
                {
                    "title": "YES",
                    "const": true
                },
                {
                    "title": "NO",
                    "const": false
                }
            ]
        },
        "clearance": {
            "type": "boolean",
            "title": "Custom Clearance Status?",
            "oneOf": [
                {
                    "title": "YES",
                    "const": true
                },
                {
                    "title": "NO",
                    "const": false
                }
            ]
        },
        "transportDate": {
            "type": "string",
            "title": "Date of transport",
            "format": "date-time"
        },
        "estimate_duration": {
            "title": 'Estimate Duration',
            "type": 'string',
        },
        "freightId": {
            "title": 'Freight ID',
            "type": 'string'
        },
    },
    "required": ["freightId", "estimate_duration", "transportDate", "clearance", "humidity", "temp"]
}

export const PortUiSchema = {
    "temp": {
        "ui:widget": "radio",
        "ui:options": {
            "inline": true
        }
    },
    "humidity": {
        "ui:widget": "radio",
        "ui:options": {
            "inline": true
        }
    },
    "clearance": {
        "ui:widget": "radio",
        "ui:options": {
            "inline": true
        }
    },
    "ui:submitButtonOptions": {
        "submitText": "Proceed",
        "norender": false,
        "props": {
            "disabled": false,
            "className": `submitBtn`
        }
    },

}

export const HarvestSchema: RJSFSchema = {
    "type": 'object',
    "required": ['amount', 'unit', 'harvestMethod', 'collectionDate', "location", "cropColor"],
    "properties": {
        "location": {
            "type": "string",
            "title": "Location Details"
        },
        "amount": {
            "type": "number",
            "title": "Quantity",
        },
        "unit": {
            type: "string",
            title: 'Unit',
            default: 'TONNE',
        },
        "tss": {
            "type": "object",
            "title": "TSS",
            "properties": {
                "amount": {
                    "type": "number",
                    "title": "Value",
                },
                "unit": {
                    type: "string",
                    title: 'Unit',
                    default: 'DEGREES_BRIX',
                    // default: 'PERCENTAGE',
                }
            },
            required: ["amount", "unit"]
        },
        "cropColor": {
            "type": 'string',
            title: 'Color of the fruit',
        },
        "diseaseIncidence": {
            "type": "object",
            "title": "Disease Incident",
            "properties": {
                "amount": {
                    "type": "number",
                    "title": "Value",
                },
                "unit": {
                    type: "string",
                    title: 'Unit',
                    default: 'PERCENTAGE',
                }
            },
            required: ["amount", "unit"]
        },
        "harvestMethod": {
            "type": 'string',
            title: 'Method of Harvest'
        },
        collectionDate: { type: 'string', title: 'Collection Date', },
    },
}

export const HarvestUiSchema = {
    "tss": {
        "amount": {
            "ui:widget": "text",
        },
    },
    "diseaseIncidence": {
        "amount": {
            "ui:widget": "text",
        },
    },
    "amount": {
        "ui:widget": "text",
    },
    "harvestMethod": {
        "ui:widget": "autocomplete",
        "ui:options": METHODS_OF_HARVEST,
    },
    "collectionDate": {
        "ui:widget": "datepicker",
    },
    "location": {
        "ui:widget": "location",
    },
    "ui:classNames": "modalContainer",
    "ui:submitButtonOptions": {
        "submitText": "Save Info",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `buttonWrap modalButton`,
        }
    },
}

export const FarmSchema: RJSFSchema = {
    type: 'object',
    "required": ['assignee', 'targetQty', "collectionCompletionDate"],
    properties: {
        "assignee": {
            "title": 'Select Field Officer',
            "type": 'string',
        },
        targetQty: {
            type: 'object', title: 'Quantity',
            properties: {
                "amount": {
                    "type": "number",
                    "title": "Value",
                },
                "unit": {
                    type: "string",
                    title: 'Unit',
                    default: 'TONNE',
                }
            },
            required: ["amount", "unit"],
        },
        "collectionCompletionDate": {
            "type": "string",
            "title": "Exp. Completion Date",
        },
    },
}


export const FarmUiSchema = {
    // "officer": {
    //     "ui:widget": "autocomplete",
    //     // "ui:options": EXPORTER,
    // },
    "targetQty": {
        "amount": {
            "ui:widget": "text",
        },
    },
    "collectionCompletionDate": {
        "ui:widget": "datepicker",
    },
    "ui:submitButtonOptions": {
        "submitText": "Allocate",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `primaryBtn`
        }
    },
}


export const shippmentSchema: RJSFSchema = {
    type: 'object',
    required: ['vehicleNumber', 'qty', 'driverName'],
    properties: {
        "vehicleNumber": {
            "type": "string",
            "title": "Vehicle Number",
        },
        qty: {
            type: 'object', title: 'Quantity',
            properties: {
                "amount": {
                    "type": "number",
                    "title": "Value",
                },
                "unit": {
                    type: "string",
                    title: 'Unit',
                    default: 'TONNE',
                }
            },
            required: ["amount", "unit"],
        },
        "driverName": {
            "type": "string",
            "title": "Driver's Name",
        },
        // "evidence": {
        //     "type": "array",
        //     "title": "Evidence(s)",
        //     "items": {
        //         "type": "string",
        //         "format": "data-url"
        //     }
        // },
    },
}

export const shippmentUiSchema = {
    "qty": {
        "amount": {
            "ui:widget": "text",
        },
    },
    "ui:submitButtonOptions": {
        "submitText": "Create Batch",
        "norender": false,
        "props": {
            "disabled": false,
            "className": `primaryBtn`
        }
    },
}


export const packhouseSchema: RJSFSchema = {

    type: 'object',
    required: ['packhouselocation', 'lotnumber'],
    properties: {
        "packhouselocation": {
            "type": "string",
            "title": "Packhouse Location",
        },
        "lotnumber": {
            "type": "string",
            "title": "Lot Number",
        },


    }
};


export const packhouseUiSchema = {

    "ui:submitButtonOptions": {
        "submitText": "Submit",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `submitBtn`
        }
    },
};


export const exporterSchema: RJSFSchema = {
    type: 'object',
    required: ['name', 'address', "mobile", "exporterIECNo", "apedaRCMC", "gstNumber", "pan", "tin", "email"],
    properties: {
        "name": {
            "type": "string",
            "title": "Legal Name",
        },
        "address": {
            "type": "string",
            "title": "Registered Address",
        },
        "mobile": {
            "type": "string",
            "title": "Contact No.",
            "pattern": "^[0-9]{10}$"
        },
        "email": {
            "type": 'string',
            "title": "Email",
            "pattern": "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$",
        },
        "gender": {
            "type": "string",
            "title": "Gender",
            "enum": ["Female", "Male", "Transgender", "Prefer not to respond"],
        },
        // "aadhaar": {
        //     "type": "string",
        //     "title": "Aadhaar No",
        //     "pattern": "^[2-9]{1}[0-9]{11}$"
        // },
        "pan": {
            "type": "string",
            "title": "PAN",
            "pattern": "^[A-Z]{5}[0-9]{4}[A-Z]$",
        },
        "gstNumber": {
            "type": "string",
            "title": "GST Number",
            "pattern": "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
        },
        "tin": {
            "type": "string",
            "title": "CIN",
            "pattern": '^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$',
        },
        "exporterIECNo": {
            "type": "string",
            "title": "IEC number",
            "pattern": "^[0-9A-Z]{10}",
        },
        "apedaRCMC": {
            "type": "string",
            "title": "APEDA RCMC",
            // "pattern":"^[0-9]{6}"
        },
    }
}
export const exporterUISchema = {
    "ui:submitButtonOptions": {
        "submitText": "Register Now",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `primaryBtn`
        }
    },
};

export const traderSchema: RJSFSchema = {
    type: 'object',
    required: ['name', "email", "mobile", "address", "pan", "gstNumber", "tin"],
    properties: {
        "name": {
            "type": "string",
            "title": "Name",
        },
        // "gender": {
        //     "type": "string",
        //     "title": "Gender",
        //     "enum": ["Female", "Male", "Transgender", "Prefer not to respond"],
        // },
        "email": {
            "type": 'string',
            "title": "Email",
            "pattern": "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$",
        },
        // "age": {
        //     "type": "string",
        //     "title": "Age",
        //     "enum": ["18-24", "25-34", "35-39", "40-49", "50+"],
        // },
        "mobile": {
            "type": "string",
            "title": "Contact No.",
            "pattern": "^[0-9]{10}$",
        },
        "address": {
            "type": "string",
            "title": "Address",
        },
        "pan": {
            "type": "string",
            "title": "PAN",
            "pattern": "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
        },
        "gstNumber": {
            "type": "string",
            "title": "GST Number",
            "pattern": "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
        },
        "tin": {
            "type": "string",
            "title": "TIN",
            "pattern": '^[0-9]{9}[0-9]{2}$',
        },
    }
}
export const traderUISchema = {
    "ui:submitButtonOptions": {
        "submitText": "Register Now",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `primaryBtn`
        }
    },
};

export const officerSchema: RJSFSchema = {
    type: 'object',
    required: ['name', 'email', "mobile", "address", "aadhaar"],
    properties: {
        "name": {
            "type": "string",
            "title": "Name",
        },
        "mobile": {
            "type": "string",
            "title": "Contact No.",
            "pattern": "^[0-9]{10}$"
        },
        "email": {
            "type": 'string',
            "title": "Email",
            "pattern": "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$",
        },
        "address": {
            "type": "string",
            "title": "Address",
        },
        "aadhaar": {
            "type": "string",
            "title": "Aadhaar No",
            "pattern": "^[2-9]{1}[0-9]{11}$"
        },
    }
}
export const officerUISchema = {
    "ui:submitButtonOptions": {
        "submitText": "Register Now",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `primaryBtn`
        }
    },
};

export const farmerSchema: RJSFSchema = {
    type: 'object',
    required: ['name', 'gender', "mobile", "address", "idval", "apedaReg"],
    properties: {
        "name": {
            "type": "string",
            "title": "Name",
        },
        "gender": {
            "type": "string",
            "title": "Gender",
            "enum": ["Female", "Male", "Transgender", "Prefer not to respond"],
        },
        // "age": {
        //     "type": "string",
        //     "title": "Age",
        //     "enum": ["18-24", "25-34", "35-39", "40-49", "50+"],
        // },
        "mobile": {
            "type": "string",
            "title": "Contact No.",
            "pattern": "^[0-9]{10}$"
        },
        "address": {
            "type": "string",
            "title": "Village",
        },
        "idval": {
            "type": "string",
            "title": "Aadhaar No",
            "pattern": "^[2-9]{1}[0-9]{11}$"
        },
        "apedaReg": {
            "type": "string",
            "title": "APEDA hortinet registration No.",
        },
    }
}
export const farmerUISchema = {
    "ui:submitButtonOptions": {
        "submitText": "Register Now",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `primaryBtn`
        }
    },
};


export const newFarmSchema: RJSFSchema = {
    type: 'object',
    required: ["location", "name", "landRecordNumber", "farmerid"],
    properties: {
        "location": {
            "type": "string",
            "title": "Location",
        },
        "name": {
            "type": "string",
            "title": "Orchard Name",
        },
        "farmerid": { "title": 'Select the farmer', "type": 'string' },
        "cultivatedArea": {
            "type": "object",
            "title": "Orchard area",
            "required": ["amount", "unit"],
            "properties": {
                "amount": {
                    "type": "number",
                    "title": "Total Area",
                },
                "unit": { "title": 'Unit', "type": 'string', "default": 'ACRE' },
            }
        },
        "landRecordNumber": {
            "type": "string",
            "title": "Land Record Number",
        },
        "cultivatedItems": {
            "title": "Cultivated Crop(s)",
            'type': 'array',
            "items": [
                {
                    "title": "Primary Crop",
                    "$ref": "#/definitions/crop",
                }
            ],
            "additionalItems": {
                "title": "Additional Crop",
                "$ref": "#/definitions/crop",
            },
        },
    },
    "definitions": {
        "crop": {
            "type": "object",
            "title": "Cultivated Crop(s)",
            //  "required": ["itemType", "itemName", "cultivatedArea", "plantedTrees"],
            "properties": {
                "itemType": {
                    "type": "string",
                    "title": "Select Crop",
                },
                "itemName": { "title": 'Select Variety', "type": 'string', },
                "cultivatedArea": {
                    "type": "number",
                    "title": "Cultivated Area",
                },
                "plantedTrees": {
                    "type": "integer",
                    "title": "No. of trees",
                },
            },
        }
    }
}
export const newFarmUISchema = {
    "cultivatedArea": {
        "amount": {
            "ui:widget": "text",
        },
    },
    "ui:submitButtonOptions": {
        "submitText": "Add Now",
        "norender": true,
        "props": {
            "disabled": false,
            "className": `primaryBtn`
        }
    },
    "location": {
        "ui:widget": "location",
    },
    "cultivatedItems": {
        "items": {
            "itemType": {
                "ui:widget": "autocomplete",
                "ui:options": CROPS,
            },
            "itemName": {
                "ui:widget": "autocomplete",
                "ui:options": MANGO_VARIANT,
            },
            "cultivatedArea": {
                "ui:widget": "text",
            },
            "plantedTrees": {
                "ui:widget": "text",
            },
        },
        "additionalItems": {
            "itemType": {
                "ui:widget": "autocomplete",
                "ui:options": CROPS,
            },
            "itemName": {
                "ui:widget": "autocomplete",
                "ui:options": MANGO_VARIANT,
            },
            "cultivatedArea": {
                "ui:widget": "text",
            },
            "plantedTrees": {
                "ui:widget": "text",
            },
        }
    }
};