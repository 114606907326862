import { APP_CONSTANTS } from "../../../../configs/Utils";
import FarmerModal from "../../components/farmerDetails/farmerModal";
import './FarmSummary.scss';
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../../shared/contexts/modalContext";

const FarmSummary = (props: { farm: any }) => {
    const navigate = useNavigate()
    const farm = props.farm.farm ?? props.farm.farm
    const { openModal, closeModal } = useModal();

    const handleClose = () => {
        closeModal();
    };

    const _navigateToMap = () => {
        navigate('../../mapView', { state: farm })
    }

    const _openFarmerDetails = () =>
        openModal(<FarmerModal open={openModal} handleClose={handleClose} farmer={props.farm.farmer} />, 'md');

    return (
        <>
            {
                farm &&
                <div className='farmSummaryContainer'>
                    <div className='id'>
                        <div className='flexColumn ellipsis'>
                            <div>Farm ID</div>
                            <div className='farmValue ellipsis'>#{farm.farmId}</div>
                        </div>
                        {/* <div className='flex'>
                            {<div className='isStatus'>Active</div>}
                        </div> */}
                    </div>
                    <div className='fstatusWrap'>
                        <CardSection area={farm.area} />
                    </div>
                    <div className='farmMeta'>
                        <div className='sub' onClick={_navigateToMap}>
                            <img src={APP_CONSTANTS.IMAGE_PATH + 'location.png'} />
                            <div className='location'>
                                <div className='name'>{`${farm.coordinates?.lat}, ${farm.coordinates?.lon}`}</div>
                            </div>
                        </div>

                        <div className='farm'>
                            <br />
                            <div className='attr'>Item : <b>{farm.cultivatedItems?.[0].itemType}</b></div>
                            <div className='attr'>Total Plantation : <b>{farm.totalPlantedTrees}</b></div>
                            {/* <div className='attr'>Est. Yield : <b>{`4800`}</b></div> */}
                            <br />
                            {farm.landRecordNumber && <div className='attr'>Land Record No. : <b>{farm.landRecordNumber}</b></div>}
                            <br />
                            <br />
                            <div className='name ellipsis' onClick={_openFarmerDetails}>
                                Farmer ID : <b><u>#{props.farm?.farmer?.farmerId}</u></b>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default FarmSummary;


const CardSection = (props: { area: any }) => {

    return <div className='cardSection completedCard'>
        <div className="flex">
            <div className="cardImgWrap">
                <img src={APP_CONSTANTS.IMAGE_PATH + 'tree.png'} />
            </div>
        </div>

        <div className="flexColumn value">
            <div className='info'>Area</div>
            <div className='count'>{props.area?.amount} {props.area?.unit}</div>
        </div>

    </div>
}