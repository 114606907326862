import { createContext, useContext, useEffect, useState } from 'react';
import { APP_CONSTANTS } from '../../configs/Utils';

const DrawerContext = createContext<any>(null);

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }: any) => {
  const [drawerContent, setDrawerContent] = useState(null);

  const openDrawer = (content: any) => {
    setDrawerContent(content);
  };

  const closeDrawer = () => {
    setDrawerContent(null);
  };


  return (
    <DrawerContext.Provider value={{ openDrawer, closeDrawer }}>
      {children}
      {drawerContent && (
        <div className='offcanvas show' role="dialog" tabIndex={-1}>
            <img src={APP_CONSTANTS.IMAGE_PATH + 'cancel.png'} className='drawerCancel absolute' alt="close" onClick={closeDrawer} />
            {drawerContent}
        </div>
      )}
    </DrawerContext.Provider>
  );
};
