import  { useEffect, useState } from 'react';
import { APP_CONSTANTS } from '../../../configs/Utils';
import './welcome.scss';

const Welcome = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const imagenames: any = {
        1: 'Bagged mango after ripening',
        2: 'Bagging of fruit for quality production',
        3: 'Dashehari mango',
        4: 'Harvesting by CISH Harvester',
        5: 'Packaging in CFB box',
        6: 'Spraying of pesticide'
    }

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [window.innerWidth])
    
    const _login = () => {
        window.location.href = '/login';
    }
    return (
        <div className="welcomeWrap">

            <div className='header'>
                <div className='logowrapper'>
                    <img className="logo" src={APP_CONSTANTS.APP_LOGO} alt="logo" />
                </div>
                <div className='flex'><div className='primaryBtn' onClick={_login}>LOGIN</div></div>
            </div>


            <div className={(screenWidth > 1000 ? 'introWrap' : 'introResponsive')}>
                <div className='intro'>
                    <span>Mango (Mangifera indica L.)</span> “The King of Fruits” is known for its taste, flavor, attractive color and nutritional value. India is the major producer of mango among the countries in the world. It thrives well in almost all the regions of the country. It constitutes an important horticultural asset of Uttar Pradesh (UP). UP contributes a major share in the production of mango. Uttar Pradesh is the main mango growing state of India sharing about 34% of total mango production in the country. Indian mangoes are world famous and have great potential for export. In UP, Saharanpur, Meerut, Bulandshahr, Amroha, Varanasi, Lucknow Sitapur and Hardoi etc. are major mango growing belts.
                </div>

                <div className='imageWrapper'>
                    {[1, 2, 3, 4, 5, 6].map((key: any) => (
                        <div key={key} className='image relative'>
                            <img src={APP_CONSTANTS.IMAGE_PATH + `mangoes/${key}.jpg`} alt="mango" />
                            <div className='absolute overlayText'>{imagenames[key]}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='seperator flex'></div>

            <div className='footer flex'>
                <div className='varietyCard'>
                    <div className='varietyCardHeader'>Dashehari</div>
                    <div className='desc'>
                        The Dashehari is a commercial variety of North India. Fruit is yellow in colour, fibreless flesh and with pleasant flavour and taste and good keeping quality. Dusseheri Mango originated in Malihabad Tehsil, Lucknow district, Uttar Pradesh.  The Malihabadi Dasheri mango variety has been granted Geographical Indication (GI-125). It is characteristic of Malihabad, Mal and Kakori tehsils of Lucknow district. Typically cultivated in regions with the following approximate geographical coordinates:
                        <ul>
                            <li>Latitude: 26° 45' N to 27° 10' N </li>
                            <li>Longitude: 80° 30' E to 80° 55' E</li>
                        </ul>
                    </div>
                </div>

                <div className='varietyCard'>
                    <div className='varietyCardHeader'>Langra </div>
                    <div className='desc'>
                        The Langra mango is a highly esteemed variety known for its unique flavor, aroma, and texture. Originating in India, particularly in the region of Varanasi in Uttar Pradesh, this mango is celebrated for its deliciously sweet and tangy taste, coupled with its distinctively green skin even when ripe. The Langra mango is sought after for its juicy, fiberless flesh and is a favorite in various culinary uses, such as desserts, juices, and chutneys
                    </div>
                </div>

                <div className='varietyCard'>
                    <div className='varietyCardHeader'>Chausa</div>
                    <div className='desc'>
                        The Chausa Mango is primarily found in the state of Uttar Pradesh in India, particularly in the regions of Saharanpur. It is a renowned variety prized for its sweet, juicy flesh and smooth, glossy skin. Unlike other mango varieties, it is characterized by its mild flavor, which sets it apart. The Chausa Mango has gained popularity in various countries for its distinctive taste and texture
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Welcome;