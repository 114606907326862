import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import * as maptalks from 'maptalks';
import './mapTalks.scss'
import { addAlertMarker } from './markerUtils';
import { APP_CONSTANTS } from '../../../configs/Utils';
import Header from '../Header';
import DataViewComponent from '../map_profile/DataViewComponent';

const MapTalks = () => {
    const location = useLocation();
    const locationData = location.state;
    const [display, setdisplay] = useState(false)
    const [selectedFarm, setSelectedFarm] = React.useState<any>(null);

    let map: maptalks.Map;
    const [layer, setLayer] = React.useState<maptalks.VectorLayer>();
    const initMap = () => {
        if (!map) {
            map = new maptalks.Map('map', {
                center: [80.9462, 26.8467],
                zoom: 6.8,
                baseLayer: new maptalks.TileLayer('base', {
                    urlTemplate: 'http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
                    subdomains: ["a", "b", "c", "d"],
                    attribution: '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>'
                })
            });

            let l = new maptalks.VectorLayer('vector').addTo(map);
            setLayer(l);
        }
    }

    useEffect(() => {
        initMap();
    }, []);

    useEffect(() => {
        if (locationData.length === undefined)
            addFarmMarkers();
        else {
            let coord = [] as any
            locationData.forEach((trans: any) => {
                if (trans.location !== null) {
                    coord.push(trans)
                }
            });
            if (coord.length > 0)
                _createBatchTransition(coord)
        }
    }, [layer]);

    const _createBatchTransition = (coord: any) => {
        if (coord.length === 1) {
            const fromMarker = getTransitionMarker(coord[0], 0)
            addMarker(fromMarker)
        }
        else {
            for (let i = 0; i < coord.length - 1; i++) {
                const fromMarker = getTransitionMarker(coord[i], i)
                addMarker(fromMarker)
                const toMarker = getTransitionMarker(coord[i + 1], i + 1)
                addMarker(toMarker)
                _createConnect(fromMarker, toMarker)
            }
        }
    }

    const _createConnect = (fromMarker: any, toMarker: any) => {
        const line = getLine(fromMarker, toMarker);
        addLine(line);
    }

    const addMarker = (marker: maptalks.Marker | undefined) => {
        if (marker && !layer?.getGeometryById(marker.getId())) {
            layer?.addGeometry(marker);
        }
    }

    const getMarkerByFarm = (farm: any): maptalks.Marker => {
        const marker = new maptalks.Marker([farm.coordinates?.lon, farm.coordinates?.lat], {
            id: farm.farmId,
            properties: {
                'id': farm.farmId,
                'name': farm.farmName,
                'type': 'farm'
            },
            symbol: [
                {
                    'markerType': 'ellipse',
                    'markerFill': 'rgb(255,0,0)',
                    'markerFillOpacity': 0.8,
                    'markerLineColor': '#fff',
                    'markerLineWidth': 1,
                    'markerWidth': 40,
                    'markerHeight': 40,
                    'markerDx': 0,
                    'markerDy': -20
                },
                {
                    'markerFile': APP_CONSTANTS.IMAGE_PATH + 'farm.png',
                    'markerWidth': 40,
                    'markerHeight': 40
                },
                {
                    'textFaceName': 'sans-serif',
                    'textName': farm.farmName,
                    'textSize': 14,
                    'textDy': 10
                },

            ]
        });
        marker.on('click', (e) => {
            console.log('click', e, farm);

            getFarmAndDo(farm, e, onFarmClick);
        })
        marker.on('dblclick', (e) => {
            //   getFarmAndDo(e, onFarmDblClick);
        })
        return marker;
    }

    const onFarmClick = (farm: any) => {
        setSelectedFarm(farm);
        setdisplay(true)
    }

    const getFarmAndDo = (farm: any, e: any, callback: any) => {
        const properties = e.target.getProperties();
        if (farm.farmId === properties.id) {
            callback(farm)
        }
    }

    const addFarmMarkers = () => {
        const marker = getMarkerByFarm(locationData);
        addMarker(marker);
        if (locationData.alert) {
            addAlertMarker(marker);
        }
    }

    const getTransitionMarker = (info: any, idx: number): maptalks.Marker => {
        const marker = new maptalks.Marker([info.location?.longitude, info.location?.latitude], {
            id: idx,
            properties: {
                'id': idx,
                'name': info.transitionData.from,
            },
            symbol: [
                {
                    'markerType': 'ellipse',
                    'markerFill': 'rgb(54, 69, 79)',
                    'markerFillOpacity': 0.8,
                    'markerLineColor': '#fff',
                    'markerLineWidth': 0,
                    'markerWidth': 40,
                    'markerHeight': 40,
                    'markerDx': 0,
                    'markerDy': -10
                },
                {
                    'markerFile': APP_CONSTANTS.IMAGE_PATH + 'consignment-white.png',
                    'markerWidth': 25,
                    'markerHeight': 25
                },
                {
                    'textFaceName': 'sans-serif',
                    'textName': info.transitionData.from,
                    'textSize': 14,
                    'textDy': 10,
                    'textFill': 'rgba(255, 87, 51)'
                },

            ]
        });
        return marker;
    }

    const addLine = (line: maptalks.LineString | undefined) => {
        if (line && !layer?.getGeometryById(line.getId())) {
            layer?.addGeometry(line);
        }
    }

    const getLine = (fromMarker: maptalks.Marker, toMarker: maptalks.Marker): maptalks.LineString | undefined => {
        const fromLocation = fromMarker.getCoordinates();
        const toLocation = toMarker.getCoordinates();
        if (fromLocation && toLocation) {
            return new maptalks.ArcConnectorLine(fromMarker, toMarker, {
                id: `${fromMarker.getId()}-${toMarker.getId()}`,
                arcDegree: 90,
                showOn: 'always',
                arrowStyle: 'classic',
                arrowPlacement: 'vertex-last',
                
                symbol: {
                    'lineColor': '#FF5733',
                    'lineWidth': 3,
                    'lineDasharray': [10, 10],
                    // 'markerFile': APP_CONSTANTS.IMAGE_PATH + 'tree.png',
                    // 'markerPlacement': 'vertex', //vertex, point, vertex-first, vertex-last, center
                    // 'markerVerticalAlignment': 'middle',
                    // 'markerWidth': 30,
                    // 'markerHeight': 30
                }
            });
        }
    }

    const _onHeaderBtnClick = (btn: string) => {
        window.history.back();
    }

    return (
        <div className='contentContainer'>
            <Header component='map' onHeaderBtnClick={_onHeaderBtnClick} />
            <div className="contentBody">
                {/* <div className='col-md-12 maxHeight'> */}
                <div id="map" className='columnListCard' />
                {/* </div> */}
                {/* <div className='col-md-3 maxHeight'>
                </div> */}
                {
                    selectedFarm && display &&
                    <DataViewComponent code={selectedFarm} onDismiss={() => setdisplay(false)} />
                }
            </div>
        </div>
    )
}

export default MapTalks