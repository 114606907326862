import React, { useEffect, useState } from 'react'
import { MasterAPI } from '../../services/MasterAPI';

const TermsAndPolicies = (props: { cancel: any,title:string }) => {
    const [contentData, setcontentData] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    useEffect(() => {
        _fetchData()
    }, [])

    const _fetchData = () => {

        let obj = {}
        MasterAPI.getTermsContent().then((res: any) => {
            let data = res.data

            data.map((e: any) => {
                if (e.name === props.title) {
                    obj = e
                }
                setcontentData(obj)
            })
            setIsLoading(false)
        })
    }

    return (
        <div className='formContainer'>
            <div className="formHeader">
                <div className="formHeaderTitle">{props.title}</div>
            </div>
            <div className='formBody'>
                {/* <div className='attr'><b>Trader already exists. Want to send connect request?</b></div> */}
                {contentData &&
                    <>
                        {contentData.paragraphs && <Paragraph content={contentData.paragraphs} />}
                        {contentData.bullets && <Bullets content={contentData.bullets} />}
                        {contentData.meta && <Meta content={contentData.meta} />}
                     </>
                }
            </div>
        </div>
    )
}

export default TermsAndPolicies


const Paragraph = (content: any) => {
    return (
        <>
            {content.content.map((e: any) =>
                <p style={{margin: '5px 0'}}>{e}</p>
            )}
        </>
    )
}

const Meta = (content: any) => {
    return (
        <div className='meta-part'>
            {content.content.map((e: any, i: number) =>
                <>
                    {e.name && <div className='meta-name'>{e.order && <span>{i + 1}.&nbsp;</span>}{e.name}</div>}
                    {e.bullets && <Bullets content={e.bullets}/>}
                    {e.paragraphs && <Paragraph content={e.paragraphs} />}
                    {e.meta && <Meta content={e.meta} />}
                </>
            )}
        </div>
    )
}


const Bullets = (content: any) => {
    return (
        <div className='bullets'>
            {content.content.map((e: any) =>
                <li>{e}</li>
            )}
        </div>
    )
}