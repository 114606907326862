import * as maptalks from 'maptalks';

export const addAlertMarker = (marker: maptalks.Marker) => {
    marker.animate({
      'symbol': [
        {
          'markerType': 'ellipse',
          'markerFill': 'rgb(255,0,0)',
          'markerFillOpacity': 0.8,
          'markerLineColor': '#fff',
          'markerLineWidth': 1,
          'markerWidth': 50,
          'markerHeight': 50,
          'markerDx': 0,
          'markerDy': -20
        },
        marker.getSymbol()
      ]
    }, { repeat: true });
  }