import { APP_CONSTANTS, formatDate } from "../../../../configs/Utils";
import { useModal } from "../../../../shared/contexts/modalContext";


const CollectionUnits = (props: any) => {
    const { openModal, closeModal } = useModal();

    const _viewCollectionDetails = (collection: any) => {
        openModal(<ViewCollectionDetails data={collection} handleClose={() => closeModal()} />, 'sm');
    }

    return (
        <>
        {
            props.collections.map((collec: any, index: number) => (
                <div key={index} className="inline">
                    <div key={index} className='flex collectionCol justify-content-between' onClick={() => _viewCollectionDetails(collec)}>
                        <div className='colData flexColumn'>
                            <div className='meta'>{formatDate(collec.collectionDate)}</div>
                            <div className='meta highlights blueHighlight'><b>{collec.quantity.amount}</b> <i>{collec.quantity.unit}</i></div>
                            
                        </div>
                        <img src={APP_CONSTANTS.IMAGE_PATH + 'openarrow.png'} className='openCollectionDetailImg' />
                    </div>
                </div>
            ))
        }
        </>
    )
}

export default CollectionUnits;


const ViewCollectionDetails = (props: {data: any, handleClose: any}) => {

    return (
        <div className='formContainer'>
            <div className="formHeader">
                <div className="formHeaderTitle">Harvest Collection on {props.data.collectionDate}</div>
            </div>

            <div className='formBody collectionMeta'>
                <div className="attr">Amount Collected : <b>{props.data.quantity.amount}</b> <i>{props.data.quantity.unit}</i></div>
                { props.data.collectionMethod && <div className="attr">Collection Method : <b>{props.data.collectionMethod}</b></div> }
                { props.data.tss && <div className="attr">TSS : <b>{props.data.tss.amount}</b> <i>{props.data.tss.unit}</i></div> }
                { props.data.diseaseIncidence && <div className="attr">Disease Incidence : <b>{props.data.diseaseIncidence.amount}</b> <i>{props.data.diseaseIncidence.unit}</i></div> }
                { props.data.cropColor && <div className="attr">Crop Color : <b>{props.data.cropColor}</b> </div> }
            </div>
        </div>
    )
}