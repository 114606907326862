import { checkDatatype } from '../../../../configs/Utils';
import './submittedData.scss';

const keyTitleMap: any = {
    'geoLocation': 'Location',
    'freightDetails': 'Freight Details',
    'driverDetails': 'Driver Details',
    'deliveryDate': 'Delivery Date',
    'quantity': 'Quantity',
    'vehicleDetails': 'Vehicle Details',
    'traderBatches': 'Batch'
}


const SubmittedData = (props: {data: any}) => {


    return (
        <>
        {   
            props.data ?
                <>
                {
                    checkDatatype(props.data) === 'object' ? <DataItem data={props.data} />
                    : checkDatatype(props.data) === 'array' ? <ArrayIterable data={props.data} param='traderBatches'/>
                    : <></>
                }
                </>
            : <></>
        }
        </>
    )
}

export default SubmittedData;

const ArrayIterable = (props: {data: any, param: any}) => {
    return (
        <div>
            {
                props.data.map((d: any, idx: number) => (
                    <div key={idx} className='submittedDataArray'>
                        <h6>{keyTitleMap[props.param]} #{idx+1}</h6>
                        <DataItem data={d} />
                    </div>
                ))
            }
        </div>
    )
}


const DataItem = (props: {data: any}) => {
    return (
        <>
            {Object.keys(props.data).map((key: any) => (
                <>
                {
                    props.data[key] ? 
                    <div key={key} className="submittedData">
                        {
                            checkDatatype(props.data[key]) === 'object' ? <ItemObject data={props.data[key]} param={key} />

                            : checkDatatype(props.data[key]) === 'string' ?
                                <div className='dataGroup'>
                                    <div className='header'>{ keyTitleMap[key] ? keyTitleMap[key] : 'Details' } : <b>{props.data[key]}</b></div>
                                </div>

                            : <ArrayIterable data={props.data[key]} param={key}/>

                        }
                    </div>
                    : <></>
                }
                </>
            ))}
        </>
    )
}

const ItemObject = (props: {data: any, param: any}) => {
    return (
        <div className='dataGroup'>
            <div className='header'>{ keyTitleMap[props.param] ? keyTitleMap[props.param] : 'Details' }</div>
            <div className='dataGroupBody flex'>
                {
                    Object.keys(props.data).map((k: any) => (
                        <>
                        {
                            checkDatatype(props.data[k]) === 'object' ? <ItemObject data={props.data[k]} param={k} />
                            : <div key={k} className='dataItem'>{k} : <b>{props.data[k]}</b></div>
                        }
                        </>
                    ))
                }
            </div>
        </div>
    )
}

