import { _get, _post, _put } from "./API";

const baseURL = process.env.REACT_APP_BASE_URL;
const subpath = 'farm-mgmt';

export const FarmMngmntAPI = {
    getFarmsByTypeAndName: async (data: any) => {
        let snippet = '';
        if (data.name) snippet = `?name=${data.name}`;
        return await _get(`${baseURL}/${subpath}/farms/search/${data.type}${snippet}`);
    },

    getFarmById: async (id: string) => {
        return await _get(`${baseURL}/${subpath}/farms/${id}`);
    },

    addNewFarmer: async (data: any) => {
        return await _post(`${baseURL}/${subpath}/farmer`, data);
    },

    addNewFarm: async (farmerid: any, data: any) => {
        return await _put(`${baseURL}/${subpath}/farmer/${farmerid}/farm`, data);
    },

    getFarmerNames: async () => {
        return await _get(`${baseURL}/${subpath}/farmers`);
    },
}
