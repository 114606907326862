import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { styled } from '@mui/material/styles';
import { DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid'
import React from 'react'

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    borderRadius: 10,
    backgroundColor: 'white',
    color:
        theme.palette.mode === 'light' ? '#365219' : 'rgba(255,255,255,0.85)',
    fontFamily: [
        'Poppins',
        'sans-serif',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.mode === 'light' ?
            'linear-gradient(45deg, var(--font_color_alt) 0%, var(--primary-color-light) 100%)'
            : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
    // '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    //     borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    //         }`, 
    // },
    '& .MuiDataGrid-columnHeader': {
        fontSize: `14px`,
        fontWeight: 800,
        backgroundColor: '#67903C',
        color: 'white',
        // borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#67903C' : '#303030'}`,
    },
    // '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    //     borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#67903C' : '#303030'
    //         }`,
    // },
    '& .MuiDataGrid-cell': {
        fontSize: `13px`,
        color:
            theme.palette.mode === 'light' ? '#67903C' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
        borderRadius: 0,
    },
}));

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color='secondary'
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            renderItem={(props2) => <PaginationItem {...props2} />}
            onChange={(event: React.ChangeEvent<unknown>, value: number) =>
                apiRef.current.setPage(value - 1)
            }
        />
    );
}


const DataTableView = (props: { loading: boolean, rows: any, rowCount: number, col: any, check: boolean, pagination: any, changePagination: any, rid?: any }) => {
    return (
        <StyledDataGrid
            getRowId={props.rid}
            loading={props.loading}
            rows={props.rows}
            columns={props.col}
            pageSizeOptions={[10, 25, 50, 100]}
            rowCount={props.rowCount}
            checkboxSelection={props.check}
            slots={{ pagination: CustomPagination, }}
            // paginationMode='server'
            paginationMode='client'
            paginationModel={props.pagination}
            onPaginationModelChange={props.changePagination}
        />
    )
}

export default DataTableView