import { useEffect, useState } from 'react';
import './entityDetails.scss'
import { APP_CONSTANTS } from '../../../../configs/Utils';
import { Wallet } from '../../../../shared/containers';
import { useNavigate } from 'react-router-dom';

const EntityDetails = (props: { data: any, isLoggedUser?: boolean, entity: any, onClose?: any, profile?: boolean, nav?: any }) => {
  const [entityInfo, setData] = useState<any>({})

  useEffect(() => {
    setData(props.data);
  }, [props.data])

  const _navToGenPassword = () => {
    props.nav()
  }

  return (
    <div className='formContainer dialogFormContainer'>
      <div className="formHeader">
        <div className="formHeaderTitle">{props.entity}</div>
        {props.onClose && <button type="button" className="btn-close" onClick={() => props.onClose()}></button>}
      </div>
      <div className="formBody">
        <DetailsContent entityInfo={entityInfo} />
        {/* {
          props.isLoggedUser &&
          <Wallet minview={true} />
        }
        <br /><br /> */}
        {props.profile &&
          <div className='primaryBtn' style={{ width: '100%' }} onClick={_navToGenPassword}>Change Password</div>}
      </div>
    </div>


  )
}

export default EntityDetails;


const DetailsContent = ({ entityInfo }: any) => {
  return (
    <div className='entityContainer'>
      <div className='entityTitle flexColumn'>
        <div className='titleValue'>
          <img src={APP_CONSTANTS.IMAGE_PATH + 'user.png'} />
          <div className='flexColumn'>
            <div className='title'>{entityInfo.name}</div>
            <div className='subtitle'>{entityInfo.id}</div>
          </div>
        </div>
        <div className='addressValue'><img src={APP_CONSTANTS.IMAGE_PATH + 'location.png'} />{entityInfo.address}</div>
      </div>
      <div className='entity'>
        <div className='name'>Contact Details : </div>
        <div className='valueWrap'>
          <div className='attr'>Contact: <b>{entityInfo.mobile}</b></div>
          {entityInfo.email && <div className='attr'>Email: <b>{entityInfo.email}</b></div>}
        </div>

        <br />
        <div className='name'>Other Details :</div>
        <div className='valueWrap'>
          {entityInfo.userId && <div className='attr'>{entityInfo.userId.idType} : <b>{entityInfo.userId.idvalue}</b></div>}
          {entityInfo.businessRegNo && <div className='attr'>Business Reg. No. : <b>{entityInfo.businessRegNo}</b></div>}
          {entityInfo.exporterIECNo && <div className='attr'>Exporter IEC No. : <b>{entityInfo.exporterIECNo}</b></div>}
          {entityInfo.gstNumber && <div className='attr'>GST No. : <b>{entityInfo.gstNumber}</b></div>}
          {entityInfo.tin && <div className='attr'>TIN : <b>{entityInfo.tin}</b></div>}
          {entityInfo.apedaRCMC && <div className='attr'>APEDA RCMC : <b>{entityInfo.apedaRCMC}</b></div>}
        </div>
      </div>
    </div>
  )

}
