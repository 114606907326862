import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

const DatePickerWidget = (props: any) => {
    const { value, onChange } = props;

    const handleDateChange = (date: any) => {
        onChange(dayjs(date).format('YYYY-MM-DD'))
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label={props.label}
                value={value !== undefined ? dayjs(value) : null}
                onChange={(newV) => handleDateChange(newV)}
                format="DD-MM-YYYY" />
        </LocalizationProvider>
    )
}

export default DatePickerWidget