import { Tabs, tabsClasses } from "@mui/material";
import { useEffect, useState } from "react";
import { STATUS_DETAILS } from "../../../configs/Workflow";
import './scrollableTabsHeader.scss';


const ScrollableTabsHeader = (props: { tabs: any, activeTab?: any, onTabClick: any, type: 'consignment' | 'general', centered?: boolean }) => {
    const [stageIndex, setStageIndex] = useState(0);
    const [selectedStage, setSelectedStage] = useState<any>(null);

    const _onSelectStage = (stage: any, i: number) => {
        setSelectedStage(stage);
        setStageIndex(i);
        props.onTabClick(stage);
    }

    useEffect(() => {
        if (props.activeTab) {
            let index = props.tabs.findIndex((stage: any) => {
                return props.type === 'consignment' ? stage.from === props.activeTab.from : stage.name === props.activeTab.name;
            });
            _onSelectStage(props.activeTab, index);
        } else {
            if (props.tabs?.length > 0) _onSelectStage(props.tabs[0], 0);
        }
    }, [props.activeTab, props.tabs]);

    return (
        <Tabs variant={props.centered ? "fullWidth" : "scrollable"} value={stageIndex} scrollButtons className="stagesHeader" centered={props.centered}
            sx={{
                [`& .${tabsClasses.scrollButtons}`]: { '&.Mui-disabled': { opacity: 0.3 } }
            }}>
            {props.type === 'consignment' && props.tabs && props.tabs.map((stage: any, i: number) =>
                <div key={stage.from} className={'stage ' + (stage.disabled ? 'disabledItem' : '') + (selectedStage?.from === stage.from ? 'activeStage' : '')} onClick={() => _onSelectStage(stage, i)}>
                    {STATUS_DETAILS[stage.from] ? STATUS_DETAILS[stage.from].name : stage.from}
                </div>
            )}
            {/* { props.type === 'pop' && props.tabs && props.tabs.map((stage: any, i: number) =>
                <div key={stage.name} className={'stage ' + (stage.disabled ? 'disabledItem': '') + (selectedStage?.name === stage.name ? 'activeStage' : '')} onClick={() => _onSelectStage(stage, i)}>
                    {stage.value}
                </div>
            )} */}

            {props.type === 'general' && props.tabs && props.tabs.map((stage: any, i: number) =>
                <div key={stage.name} className={'stage ' + (stage.disabled ? 'disabledItem' : '') + (selectedStage?.name === stage.name ? 'activeStage' : '')} onClick={() => _onSelectStage(stage, i)}>
                    {stage.value}
                </div>
            )}
        </Tabs>
    );
}

export default ScrollableTabsHeader;