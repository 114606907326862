import { VisibilityOff, Visibility } from '@mui/icons-material'
import { IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import React from 'react'

const PasswordWidget = (props: any) => {
    const { value, onChange } = props;
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <>
            <InputLabel htmlFor="outlined-adornment-password">{props.label}</InputLabel>
            <OutlinedInput
            
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                onChange={(event) => onChange(event.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                label="Password"
            />
        </>
    )
}

export default PasswordWidget