import React, { createContext, useContext, useState } from 'react';

const EventContext = createContext<any>({});



export const EventProvider = ({ children }: any) => {
  const [event, setEvent] = useState({ type: '', payload: ''});
  return (
    <EventContext.Provider value={{ event, setEvent }}>
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = () => useContext(EventContext);
