import React, { useEffect, useState } from 'react'
import './footer.scss'
import { useModal } from '../../contexts/modalContext'
import { TermsAndPolicies } from '../../components'

const Footer = () => {
    const { openModal, closeModal } = useModal();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [window.innerWidth])

    const _readPolicies = (title: string) => {
        openModal(<TermsAndPolicies title={title} cancel={() => closeModal()} />, 'md')
    }

    return (
        <div className='footerMain'>
            <div className='section1'>
                <div className='devText'>
                    <b onClick={() => _readPolicies('Terms and Conditions')}>Terms and Conditions</b>
                </div>
                <div className='dividerHorizontal' />
                <div className='devText'>
                    <b onClick={() => _readPolicies('Privacy Policy')}>Privacy Policy</b>
                </div>
            </div>
            <div className='divider' />
            <div className='section2'>
                <div className={'devText ' + (screenWidth > 1000 ? ' ' : 'mversion inline')}>
                    Jointly Developed by
                    <div className='txt'>
                        <b onClick={() => window.open("https://www.borlaug.ws/", "_blank")}>Borlaug Web Services</b>
                        &
                        <b onClick={() => window.open("https://cish.icar.gov.in/", "_blank")}>ICAR-CISH, Lucknow</b>
                    </div>
                </div>
                Copyright &copy; {new Date().getFullYear()} All Rights Reserved.
            </div>
        </div>
    )
}

export default Footer