import { useEffect, useState } from "react";
import { formatDate } from "../../configs/Utils";
import { ViewDmsDoc } from "../components";
import { useModal } from "../contexts/modalContext";

const AttachmentsSummary = (props: {attachments: any}) => {

    const [attachments, setAttachments] = useState<any>(null);
    const { openModal, closeModal } = useModal();
    useEffect(() => {
        if (props.attachments) {
            setAttachments(props.attachments);
        }
        console.log(props.attachments);
        
    }, [props.attachments]);

    const _showImage = (code:string)=>{
        openModal(<ViewDmsDoc url={true} file={code} handleClose={() => closeModal()} />, 'sm');

    }
    return (        
        <div className='docSummaryWrap'>
            {
                attachments && attachments.map((att: any, index: number) => (
                    <div key={index} className='docSummary'>
                        <b>{index+1}. {att.attachment.doctype}  {att.attachment.extension !== ".pdf" && <u onClick={()=>_showImage(att.attachment.code)}>(View)</u>}</b>{ att.attachment.meta?.date && <span>{formatDate(att.attachment.meta.date)}</span> }
                    </div>
                ))
            }
        </div>
    );
}

export default AttachmentsSummary;