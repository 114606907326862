export const HeaderDefinitions: any = {
    'dashboard': {
        title: 'Consignments Dashboard',
        subtitleEntity: 'consignments',
        hasSearch: true
    },
    'consignment': {
        title: 'Consignment',
        subtitleEntity: 'Consignment Details',
        backBtnTitle: 'Dashboard'
    },
    'farms': {
        title: 'Farm Dashboard',
        subtitleEntity: 'Farms'
    },
    'farmDetails': {
        title: 'Farm Details',
        subtitleEntity: 'Farm Info',
        backBtnTitle: 'Farms'
    },
    'exporter': {
        title: 'Exporters Dashboard',
        subtitleEntity: 'Exporters'
    },
    'exporterDetails': {
        title: 'Exporter Details',
        subtitleEntity: 'Exporter Details',
        backBtnTitle: 'Exporters'
    },
    'trader': {
        title: 'Traders Dashboard',
        subtitleEntity: 'Info'
    },
    'traderDetails': {
        title: 'Trader Details',
        subtitleEntity: 'Trader Details',
        backBtnTitle: 'Traders'
    },
    'field_officer': {
        title: 'Field Officer Dashboard',
        subtitleEntity: 'Info'
    },
    'field_officerDetails': {
        title: 'Field Officer Details',
        subtitleEntity: 'Field Officer Details',
        backBtnTitle: 'Field Officer'
    },
    'farmers': {
        title: 'Farmer Dashboard',
        subtitleEntity: ''
        // backBtnTitle: 'Dashboard'
    },
    'farmerDetails': {
        title: 'Farmer Details',
        subtitleEntity: 'Farms',
        backBtnTitle: 'Farmers'
    },
    'map': {
        title: 'Source & Trace Map',
        subtitleEntity: ''
    },
    'notification': {
        title: 'Notifications',
        subtitleEntity: 'approval pending',
        hasSearch: true
    },
}


export const ButtonDefinitions: any = {
    TRADER: {
        'dashboard': ['Create Consignment'],
        'consignment': ['Get QR Code', 'Track & Trace'],
        'farms': ['Onboard A Farmer', 'Add New Farm'],
        'field_officer': ['Onboard a field Officer'],
        // 'exporter': ['Onboard Exporter'],
        'map': ['Cancel'],
    },
    FIELD_OFFICER: {
        'consignment': ['Track & Trace'],
        'farms': ['Onboard A Farmer', 'Add New Farm'],
        'map': ['Cancel'],
    },
    EXPORTER: {
        'consignment': ['Get QR Code', 'Track & Trace'],
        'dashboard': ['Create Consignment'],
        'trader': ['Onboard a Trader'],
        'map': ['Cancel'],
    },
    PLATFORM_ADMIN: {
        'exporter': ['Onboard Exporter'],
        // 'trader': ['Onboard a Trader'],
        'map': ['Cancel'],
    },
}