
export const STATUS_DETAILS: any = {
    'CREATED': { name: 'New Contract', buttonTitle: 'Submit', status: 'pending allocations' },
    'ASSIGNED': { name: 'Allocation Complete', buttonTitle: 'Submit', status: 'pending allocations' },
    'ALLOCATION': { name: 'Farm Allocation', buttonTitle: 'Submit', status: 'allocation in progress' },
    'COLLECTING': { name: 'Harvest Collection', buttonTitle: 'Submit', status: 'harvest in progress' },
    'COLLECTION_COMPLETED': { name: 'Collection Complete', buttonTitle: 'Send for primary processing', status: 'harvest collection completed' },
    'PRIMARY_TREATMENT': { name: 'Primary Processing', buttonTitle: 'Submit', status: 'primary processing is in progress' },
    'TRADER_TO_PACKHOUSE': { name: 'Transit to Packhouse', buttonTitle: 'Create Batch', status: 'ready for packhouse' },
    'SHIPPED_TO_PACKHOUSE': { name: 'Confirmation at Packhouse', buttonTitle: 'Confirm Batches', status: 'shipped to packhouse' },
    'SECONDARY_PROCESSING': { name: 'Secondary Processing', buttonTitle: 'Submit', status: 'secondary processing is in progress' },
    'SHIPMENT_PREPARATION': { name: 'Transit to Origination Port', buttonTitle: 'Ship to Origin Port', status: 'preparing for shipment' },
    'SHIPPED_TO_ORIGIN_PORT': { name: 'At Origination Port', buttonTitle: 'Confirm Shipment', status: 'shipped to origin port' },
    'DELIVERED_AT_ORIGIN_PORT': { name: 'Transit to Destination Port', buttonTitle: 'Export', status: 'received at origin port' },
    'SHIPPED_TO_DESTINATION_PORT': { name: '', buttonTitle: 'Submit', status: 'ready for export' },
    'DELIVERED_AT_DESTINATION_PORT': { name: '', buttonTitle: 'Submit', status: 'consignment received' },
}

export const qrStagesMap: any = {
    'consignmentCreation': 'Consignment Creation',
    'originPortShipment': 'Origin Port Shipment',
    'originPortDelivery': 'Origination Port',
    'primaryTreatment': 'Primary Processing',
    'secondaryTreatment': 'Secondary Processing',
    'destinationPortShipment': 'Destination Port'
}


export const QRStages = [
    {
        name: 'Consignment Creation',
        img: 4,
    },
    {
        name: 'Primary Processing',
        img: 1,

    },
    {
        name: 'Received at Packhouse',
        img: 1,

    },
    {
        name: 'Secondary Processing',
        img: 3,
    },
    {
        name: 'Shipment to Origination Port',
        img: 3,
    },
    {
        name: 'Origination Port',
        img: 5,
    },
]