import { SyntheticEvent } from "react";
import { getCurrentRole, getLoggedUserName } from "../../../../configs/Utils";
import './approvalWidget.scss';
import { MessageService, TRADE_MGMT_API } from "../../../../shared/services";

const ApprovalWidget = ({ consignment, onEmitStatus }: any) => {

    const _isPendingWithMe = () => {
        let userid = getLoggedUserName();
        return consignment.createdBy != userid ? true : false;
    }
    const _getApproverUser = () => {
        let role = getCurrentRole();
        // let approver = role === 'TRADER' ? `Exporter ${consignment.exporter?.idvalue}` : role === 'EXPORTER' ? `Trader ${consignment.trader?.idvalue}` : '';
        let approver = role === 'TRADER' ? `Exporter ${consignment.assigneeName}` : role === 'EXPORTER' ? `Trader ${consignment.assigneeName}` : '';
        return approver;
    }
    const _approve = (e: SyntheticEvent) => {
        TRADE_MGMT_API.approveConsignment({ "id": [consignment.id] }).then((response: any) => {
            MessageService.setStatus({ type: 'success', text: 'Consignment is approved!' });
            onEmitStatus(true);
        });
        e.stopPropagation();
    }

    return (
        <div className='flex justify-content-between approvalWrap'>
            <div className='statusDesc'>Pending Approval
                {_isPendingWithMe() === false && <span> with <b>{_getApproverUser()}</b></span>}
            </div>
            {_isPendingWithMe() === true && <div className='primaryBtn' onClick={(e) => _approve(e)}>Approve</div>}
        </div>
    );
}

export default ApprovalWidget;