import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import { SearchDropdown, DatePickerWidget, LocationWidget, PasswordWidget } from '../widgets';

interface formProps {
    schema: RJSFSchema,
    uiSchema: any,
    widget?: any,
    submitted: (data: any) => void,
    change?: (data: any) => void,
    onFormBlurTrigger?: any,
    formdata?: any,
    children?: any
}

const FormDesign = forwardRef(({ schema, uiSchema, submitted, change, onFormBlurTrigger, children, formdata }: formProps, ref) => {

    const [selectedSchema, setSelectedSchema] = useState<any>();
    const formRef: any = useRef();
    const [formInitialised, setFormInitialise] = useState(true);

    React.useEffect(() => {
        let tempStep: any[] = []
        schema &&
            delete schema['$schema']
        setSelectedSchema(schema)
    }, [schema])

    const onBlurHandler = (id: any, val: any) => {
        let property: any
        if (id) {
            property = id.split('_')
            property.shift()
        }
        if (onFormBlurTrigger) onFormBlurTrigger(property)
    }

    useImperativeHandle(ref, () => ({
        checkValidation() {
            setFormInitialise(false)
            if (formRef.current && selectedSchema) {
                let err = formRef.current.validateForm();
                return err ? err : false
            }
            return true
        },
        handleSubmit() {
            if (formRef.current) {
                formRef.current.submit();
            }
        }
    }));

    const onHandleSubmit = (data: any) => {
        submitted(data.formData)
    }

    const onChange = (data: any) => {
        if (change)
            change(data.formData)
    }

    const getErrorProp = (error: any) => {
        // console.log('getErrorProp', error);

        const error_prop = error?.property?.split('.');
        let prop = selectedSchema
        error_prop?.forEach((element: any) => {
            if (element && element != "") {
                prop = prop.properties[element]
            }
        });
        return prop
    }

    const transformErrors = (errors: any) => {

        return errors.map((error: any) => {
            // use error messages from JSON schema if any
            const errorProp = getErrorProp(error)
            if (error.name && error.name == "required") { error.message = 'This is a required field' }
            if (selectedSchema) {
                return errorProp.messages && errorProp.messages[error.name] ? {
                    ...error,
                    message: errorProp.messages[error.name]
                } : {
                    ...error,
                    message: `Invalid ${errorProp.title}`
                };
            }
            return error;
        });
    }

    return (
        <>
            {selectedSchema &&
                <Form
                    ref={formRef}
                    showErrorList={false}
                    transformErrors={transformErrors}
                    schema={selectedSchema}
                    uiSchema={uiSchema}
                    validator={validator}
                    formData={formdata}
                    widgets={{ autocomplete: SearchDropdown, datepicker: DatePickerWidget, location: LocationWidget , passwordwidget:PasswordWidget}}
                    onSubmit={onHandleSubmit}
                    onChange={onChange}
                    // liveValidate={formInitialised ? false : true}
                    liveValidate
                    onBlur={onBlurHandler}
                >
                </Form>
            }
        </>
    )
})

export default FormDesign;


// const formdata = {
//     "item":{"itemType":"Mango","itemName":"Langra"},"orderQty":{"unit":"TONNE","amount":12},"exporter":"Q05BVFVWV1gxMjM0Uw==","transportMode":"air","destinationCountryCode":"GB"
// }