import { _get, _post, _put } from "./API";

const baseURL = process.env.REACT_APP_BASE_URL;
const subpath = 'data-mgmt';
const fetchedDataSet: any = {};


export const DATA_MGMT_API = {
  getCountries: async () => {
    if (!fetchedDataSet['countries']) 
      fetchedDataSet['countries'] = await _get(`${baseURL}/${subpath}/countries`);
    return fetchedDataSet['countries'];
  },

  getUiSchema: async () => {
    return await _get(`${baseURL}/${subpath}/schema`);
  }
}