import { APP_CONSTANTS, formatDate } from '../../../configs/Utils';
import { ViewDmsDoc } from '../../components';
import { useModal } from '../../contexts/modalContext';
import './UploadWidget.scss';
import { createContext, useContext, useEffect, useState } from 'react';
let uploadCtxt = createContext({});

const UploadWidget = (props: { multiple?: false, documents?: any, handleFileChange?: any, attachments?: any, readonly?: boolean, fileCategory?: any, metaNotRequired?:boolean }) => {
    // let docsHandler_subscription: any;
    // const [requiredStageDocs, setRequiredStageDocs] = useState<any>(null);
    const [metaNotRequired] = useState(props.metaNotRequired || false);
    const [documents, setDocuments] = useState<any>([]);
    const [selectedDocs, setSelectedDocs] = useState<any>([]);
    const [mappedAttributesToDocSteps, setMappedAttributesToDocSteps] = useState<any>({});
    const [attachmentsMapByDocSteps, setAttachmentsMapByDocSteps] = useState<any>({});
    const { openModal, closeModal } = useModal();

    const _handleFileChange = (e: any) => {
        let docs = [...selectedDocs];
        docs.push(e);
        setSelectedDocs(docs);
        props.handleFileChange(docs);
    }

    const _handleDateChange = (e: any) => {
        let docs = [...selectedDocs];
        docs.map((doc: any) => {
            if (doc.type === e.type) {
                doc.date = e.date;
            }
        })
        setSelectedDocs(docs);
        props.handleFileChange(docs);
    }

    const _onInitiateDocumentSelection = (isNew: boolean) => {
        // let maps = isNew ? {} : { ...mappedAttributesToDocSteps };
        let maps: any = {};
        documents.forEach((trt: any) => {
            let flag = maps[trt.type]?.selected || false;
            if (trt.mandatory) flag = true;
            maps[trt.type] = maps[trt.type] ? { ...maps[trt.type], selected: flag } : { selected: flag };
        })
        setMappedAttributesToDocSteps(maps);
    }

    const _onToggleClick = (trt: any, type: 'selection' | 'date') => {
        let maps = { ...mappedAttributesToDocSteps };
        switch (type) {
            case 'selection':
                let flag = !maps[trt.type]?.selected;
                if (trt.mandatory) flag = true;
                maps[trt.type] = maps[trt.type] ? { ...maps[trt.type], selected: flag } : { selected: flag };
                break;
            case 'date':
                maps[trt.type] = maps[trt.type] ? { ...maps[trt.type], date: trt.date } : { date: trt.date };
                break;
        }
        setMappedAttributesToDocSteps(maps);
    }

    useEffect(() => {
        if (props.attachments?.length > 0) {
            let maps: any = {};
            props.attachments.forEach((att: any) => {
                maps[att.attachment.doctype] = maps[att.attachment.doctype] ? [...maps[att.attachment.doctype], att.attachment] : [att.attachment];
            })
            setAttachmentsMapByDocSteps(maps);
        } else setAttachmentsMapByDocSteps({});
    }, [props.attachments])

    useEffect(() => {
        if (documents.length > 0)
            _onInitiateDocumentSelection(true);
    }, [documents])

    useEffect(() => {
        if (props.documents && props.documents.length > 0) {
            setDocuments(props.documents);
        } else setDocuments([{ type: props.fileCategory ? props.fileCategory : 'Evidence' }]);
    }, [props.documents, props.fileCategory])

    const _toViewDoc = (file: any, ext:string) => {
        openModal(<ViewDmsDoc file={file} extension={ext} handleClose={() => closeModal()} />, 'md');
    }

    return (
        <uploadCtxt.Provider value={{ mappedAttributesToDocSteps, attachmentsMapByDocSteps, metaNotRequired, _handleFileChange, _onToggleClick, _handleDateChange }}>
            {
                documents.length > 0 && attachmentsMapByDocSteps ?
                    documents.map((trt: any, index: any) => (
                        <div className={'flexColumn upload_attachments_wrapper ' + (attachmentsMapByDocSteps[trt?.type] ? 'hasAttachmentsWrapper' : '')} key={index}>
                            <div className="uploadWrapper">
                                <UploadWrapper trt={trt} documents={selectedDocs.filter((sd: any) => sd.type === trt.type)[0]?.files} readonly={props.readonly} />
                            </div>
                            {attachmentsMapByDocSteps[trt?.type] && <div className="attachmentsWrapper">
                                {
                                    attachmentsMapByDocSteps[trt?.type].map((att: any, index: any) => (
                                        <div key={index} className='docTitle inline' onClick={() => _toViewDoc(att.code,att.extension)}>{att.code}</div>
                                    ))
                                }
                            </div>}
                        </div>
                    ))
                    :
                    <div className='flexColumn upload_attachments_wrapper '>
                        <div className="uploadWrapper">
                            <UploadWrapper documents={selectedDocs[0]?.files} readonly={props.readonly} />
                        </div>
                    </div>
            }

        </uploadCtxt.Provider>
    )
}

export default UploadWidget;


const UploadWrapper = (props: { trt?: any, documents: any, readonly: any }) => {

    const { mappedAttributesToDocSteps, attachmentsMapByDocSteps, metaNotRequired, _handleFileChange, _onToggleClick, _handleDateChange }: any = useContext(uploadCtxt);

    const fileChange = (e: any) => {
        _handleFileChange({ type: props.trt ? props.trt.type : 'Evidence', files: e.target.files });
    }

    const isDocTypeSelected = () => {
        return mappedAttributesToDocSteps[props.trt?.type]?.selected;
    }
    const _isInputDisabled = () => {
        if (!props.trt) {
            return false;
        } else {
            return !isDocTypeSelected();
        }
    }
    // useEffect(() => {
    //     if (props.trt?.mandatory) _onToggleClick(props.trt, 'selection');
    // }, [props.trt])
    return (
        <>
            <div className="dropdownWrapper inline">
                <div className='flexColumn'>
                    <div className='treatTitle'>
                        {/* {!props.readonly && props.trt?.type && (!mappedAttributesToDocSteps[props.trt?.type]?.selected && !props.trt?.mandatory) && <img src={APP_CONSTANTS.IMAGE_PATH + 'toggle-off.png'} className="toggleicon" onClick={() => _onToggleClick(props.trt, 'selection')} />}
                        {!props.readonly && props.trt?.type && (mappedAttributesToDocSteps[props.trt?.type]?.selected || props.trt?.mandatory) && <img src={APP_CONSTANTS.IMAGE_PATH + 'toggle-on.png'} className="toggleicon" onClick={() => _onToggleClick(props.trt, 'selection')} />} */}
                        {!props.readonly && props.trt && !isDocTypeSelected() && <img src={APP_CONSTANTS.IMAGE_PATH + 'toggle-off.png'} className="toggleicon" onClick={() => _onToggleClick(props.trt, 'selection')} />}
                        {!props.readonly && props.trt && isDocTypeSelected() && <img src={APP_CONSTANTS.IMAGE_PATH + 'toggle-on.png'} className="toggleicon" onClick={() => _onToggleClick(props.trt, 'selection')} />}

                        {props.trt ? props.trt.type : 'Evidence'}
                        {props.trt ? props.trt.mandatory && <span style={{ color: 'red' }}>*</span> : ''}
                    </div>
                    {props.trt && <div className='treatDesc'> {props.trt.description ? props.trt.description : ''}</div>}
                </div>
            </div>
            {attachmentsMapByDocSteps[props.trt?.type] ?
                <div className='flex'>
                    <div className='treatfiles link'>{attachmentsMapByDocSteps[props.trt?.type].length} files</div>
                    <div className='treatfiles'>{formatDate(attachmentsMapByDocSteps[props.trt?.type][0]?.meta?.date)}</div>
                </div>
                :
                props.readonly ?
                    <div className='flex'>
                        <div className='treatfiles'>No files attached</div>
                    </div>
                    : <div className='flex'>
                        {props.documents && props.documents.length > 0 && <div className='treatfiles'>{props.documents.length} files</div>}
                        <div className={"uploadContainer inline " + (_isInputDisabled() ? 'disabledItem' : '')}>
                            <input type="file" multiple={props.trt ? props.trt.multipleAllowed : true} className="fileInput" onChange={fileChange} />
                            <label className="uploadButton">Choose File</label>
                        </div>
                        { !metaNotRequired && <input type="date" className={"dateInput " + (_isInputDisabled() ? 'disabledItem' : '')} onChange={(e) => _handleDateChange({ type: props.trt ? props.trt.type : 'Evidence', date: e.target.value })} /> }
                    </div>

            }

        </>
    )
}
