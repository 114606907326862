import { BehaviorSubject } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { APP_CONSTANTS, getCurrentRole, getLoggedUserId } from '../../configs/Utils';
import { TRADE_MGMT_API } from './TradeManagementAPI';
import { UserMngmntAPI } from './UserMgmntAPI';
import { DmsAPI } from './DmsAPI';

const loaderSubject = new BehaviorSubject(false);
const statusSubject = new BehaviorSubject<any>({});
const statusSubjectHandler = new BehaviorSubject<any>({});
const consignmentHandler = new BehaviorSubject<any>(null);
const consignmentDocumentsHandler = new BehaviorSubject<any>(null);
const schemaHandler = new BehaviorSubject<any>(null);
const fieldOfficersHandler = new BehaviorSubject<any>(null);
const pendingConsignmentsCountHandler = new BehaviorSubject<any>(null);
const inviteTraderHandler = new BehaviorSubject<any>(null);
const reloadStageProcessor = new BehaviorSubject<any>(null);

const MessageService = {
    setLoading: (f: boolean) => {
        loaderSubject.next(f);
    },
    setStatus: (f: { type: string, text: string, subtext?: string } | null) => {
        statusSubject.next(f);
        setInterval(() => statusSubject.next(null), 6000);
    },
    handleUserStatus: (f: boolean) => {
        statusSubjectHandler.next(f);
    },
    emitSelectedConsignment: (f: any) => {
        consignmentHandler.next(f);
    },
    decodeToken: (res: any) => {
        const user: any = jwtDecode(res.accessToken);
        localStorage.setItem(APP_CONSTANTS.TOKEN, res.accessToken);
        localStorage.setItem(APP_CONSTANTS.REFRESH_TOKEN, res.refreshToken);
        localStorage.setItem(APP_CONSTANTS.USERID, user.uid);
        localStorage.setItem(APP_CONSTANTS.USERNAME, user.sub);
        localStorage.setItem(APP_CONSTANTS.ROLE, user.scope);
    },
    emitConsignmentDocuments: (f: any) => {
        consignmentDocumentsHandler.next(f);
    },
    emitSchemaProperties: (f: any) => {
        schemaHandler.next(f);
    },
    emitFieldOfficers: (f: any) => {
        fieldOfficersHandler.next(f);
    },
    emitPendingConsignmentsCount: (f: any) => {
        pendingConsignmentsCountHandler.next(f);
    },
    emitStageTransmissionError: (f: any) => {
        reloadStageProcessor.next(f);
    },
    getFormDataFiles: (files: any) => {
        var formData = new FormData();
        for (const f of files) 
            formData.append('files', f);
        return formData;
    },
    getDocsResponseStructureFromPromises: async (docs: any, cid?: string | null, isPublic?: boolean) => {
        let promises = await MessageService.getDocumentsUploadPromises(docs, cid, isPublic);
        let restructuredResponse: any = [];
        return Promise.all(promises).then((res: any) => {
            let d: any[] = res[0].body;
            d.forEach((dobj: any, idx: number) =>
                restructuredResponse.push({ comments: "", attachment: { code: dobj.documentId, doctype: docs[idx].type, meta: { date: docs[idx].date } } })
            );
            return restructuredResponse;
        });

    },
    getDocumentsUploadPromises: async (docs: any, cid?: string | null, isPublic?: boolean) => {
        let docsFormData = await MessageService.getDocumentsUploadStructure(docs, cid);
        let promises: any = [];
        let apiurl = isPublic ? DmsAPI.submitPublicDocumentation : DmsAPI.submitDocumentation;
        for (const docFD of docsFormData) {
            promises.push(
                apiurl(docFD).then((res: any) => {
                    return res;
                })
            );
        };
        return promises;
    },
    getDocumentsUploadStructure: (docs: any, cid?: string | null) => {
        return docs.map((doc: any) => {
            let fd = new FormData();
            for (const f of doc.files) 
                fd.append('files', f);
            fd.append('comments', doc.comments ? doc.comments: '');
            fd.append('author', doc.author ? doc.author: '');
            fd.append('docType', doc.type ? doc.type: '');
            fd.append('meta', doc.date ? JSON.stringify({ date: doc.date }) : '');
            if (cid) fd.append('cid', cid);
            return fd;
        });
    },
    // triggerTransition: (stage: any) => {
    //     getSchema(stage).then((obj: any) => {
    //         return obj ? true : false;
    //     });
    // },
    // extractPendingConsignmentsConnectedToMe: async (consignments: any[]) => {
    //     let role = getCurrentRole();
    //     let userid = getLoggedUserId();
    //     switch(role) {
    //         case 'EXPORTER':
    //             return consignments.filter((c: any) => c.tradeOfficerId === userid);
    //         case 'TRADE_ADMIN':
    //             return consignments;
    //         default:
    //             return consignments;
    //     }
    // },
    transitStage: (conId: any, method?: any, payload?: any) => {
        TRADE_MGMT_API.submitStage(conId, method, payload).then((res: any) => {
            MessageService.setStatus({ type: 'Success', text: 'Stage transitioned successfully!' });
            MessageService.emitSelectedConsignment(conId);
        });
    },
    createTraderConnect: (id: any) => {
        MessageService.setStatus({ type: 'error', text: 'User exists! Try to connect '});
        inviteTraderHandler.next(id);
    },
    getAllFieldOfficers: async () => {
        UserMngmntAPI.searchBasedUser('FIELD_OFFICER').then((res) => {
            MessageService.emitFieldOfficers(res);
        })
    }
}


export { MessageService, statusSubject, loaderSubject, statusSubjectHandler, consignmentHandler, consignmentDocumentsHandler, schemaHandler,
     fieldOfficersHandler, pendingConsignmentsCountHandler, inviteTraderHandler, reloadStageProcessor};