import React, { useState } from 'react'
import { APP_CONSTANTS, fetchLocation } from '../../configs/Utils'
import { MessageService } from '../services';

const LocationWidget = (props: any) => {
    const [position, setPosition] = React.useState<any>({ latitude: null, longitude: null });
    const { value, onChange } = props;

    const _getLocation = () =>
        fetchLocation()
            .then((obj: any) => {
                if (obj.latitude && obj.longitude){
                    setPosition(obj)
                    onChange(JSON.stringify(obj));
                }
                else
                    MessageService.setStatus({ type: 'Warning', text: "Geolocation is not available in your browser." })
            })

    return (
        <div className='locationWrap'>
            {
                position.latitude && position.longitude ?
                    <div className='location'><img src={APP_CONSTANTS.IMAGE_PATH + 'location.png'} />
                            Location: <b>{position.latitude},{position.longitude}</b>
                    </div>
                    : <div className='tertiaryBtn' onClick={_getLocation}><img src={APP_CONSTANTS.IMAGE_PATH + 'location.png'} /> Use Current Location</div>
            }
        </div>
    )
}

export default LocationWidget