import { Backdrop, Box, Fade, Modal, } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { APP_CONSTANTS, fetchLocation } from '../../../configs/Utils';
import { MessageService } from '../../../shared/services';
import { FormDesign } from '../../../shared/components';
import { HarvestSchema, HarvestUiSchema } from '../../../configs/Schemas';
import { UploadWidget } from '../../../shared/widgets';

const CollectionForm = (props: { onFormChange: any }) => {

    const [reqBody, setReqData] = React.useState({});
    const [position, setPosition] = React.useState<any>({ lat: null, lon: null });
    const formRef: any = useRef<any>(null);
    // const [upload, setupload] = React.useState(false)

    const onDataChange = (e: any) => {
        if (e.location) {
            let parse = JSON.parse(e.location);
            setPosition({ lat: parse.latitude, lon: parse.longitude })
        }
        setReqData({ ...reqBody, qty: { amount: e.amount, unit: e.unit }, tss: e.tss, harvestMethod: e.harvestMethod, collectionDate: e.collectionDate, location: position, diseaseIncidence: e.diseaseIncidence, cropColor: e.cropColor });
    }

    const handleFileChange = (e: any) => {
        setReqData({ ...reqBody, files: e });
    }

    const onSubmitChange = () => {
        // setupload(false)
        if (formRef.current.checkValidation()) {
            props.onFormChange(reqBody);
        }

    }

    // useEffect(() => {
    //     fetchLocation()
    //         .then((obj: any) => {
    //             if (obj.latitude && obj.longitude)
    //                 setPosition({ lat: obj.latitude, lon: obj.longitude })
    //             else
    //                 MessageService.setStatus({ type: 'Warning', text: "Geolocation is not available in your browser." })
    //         })
    // }, [position])


    return (
        <div className="formContainer">
            <div className="formHeader">
                <div className="formHeaderTitle">Add Collection</div>
            </div>
            <div className='formBody'>
                {/* <div className='locationWrap'>
                    {position.lat && position.lon ? (
                        <div className='location'>
                            <img src={APP_CONSTANTS.IMAGE_PATH + 'location.png'} /> {position.lat} {position.lon}
                        </div>
                    ) : <></>}
                </div> */}
                <FormDesign
                    ref={formRef}
                    schema={HarvestSchema}
                    uiSchema={HarvestUiSchema}
                    submitted={() => { }}
                    change={onDataChange} />

                <br />
                <UploadWidget multiple={false} handleFileChange={handleFileChange} />
                <br />

                <div className='formButtonGroup'>
                    <div className="formBtn primaryBtn" onClick={onSubmitChange}>Add Collection</div>
                    <div className="formBtn secondaryBtn" onClick={() => props.onFormChange()}>Cancel</div>
                </div>

            </div>
        </div>
    )
}

export default CollectionForm