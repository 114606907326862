import { Outlet, useNavigate } from "react-router-dom";
import {EventProvider, SideNav} from "../components";
import { APP_CONSTANTS } from "../../configs/Utils";
import { useEffect, useState } from "react";
// import { useEvent } from "../components/eventProvider";

const SPAContainer = () => {

    const [openSidenav, setOpenSidenav] = useState<boolean>(false);
    // const { event } = useEvent();

    const _onOpenSideNav = () => {
        setOpenSidenav(!openSidenav);
    }

    const triggerBack = (val: boolean) => {
        setOpenSidenav(val);
    }

    // useEffect(() => {
    //     if(event && event.type === 'trigger_sidenav') {
    //         setOpenSidenav(event.payload);
    //     }
    // }, [event]);

    return (
        // <EventProvider>
        <div className="SPAWrap">
            <div className="mobileNavToggler absolute" onClick={_onOpenSideNav} >
                <img src={APP_CONSTANTS.IMAGE_PATH + 'menu.png'} alt="menu"/>
            </div>
            <div className="sidenavWrap maxHeight">
                <SideNav _triggerBack={(e: any) => triggerBack(e)}/>
            </div>
            <div className={"sidenavMobile maxHeight " + (openSidenav ? 'sidenavMobileOpen' : '')}>
                <SideNav _triggerBack={(e: any) => triggerBack(e)}/>
            </div>
            <div className="contentWrap maxHeight">
                <Outlet />
            </div>
        </div>
        // </EventProvider>
    );
}

export default SPAContainer;