
import axios from 'axios';
import { MessageService } from './helper.services';
import { ACTION_CONSTANTS } from '../../configs/Utils';

let config = {
  headers: {
    'Access-Control-Allow-Origin': '*',
  }
};
export const _get = async (url: string, extraData?: any) => {
  if (extraData) {
    config = { ...config, ...extraData }
  }
  try {
    const response = await axios.get(url, config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const _post = async (url: string, data: any) => {
  try {
    const response = await axios.post(url, data, config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const _put = async (url: string, data?: any) => {
  try {
    const response = await axios.put(url, data, config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const _patch = async (url: string, data?: any) => {
  try {
    const response = await axios.patch(url, data, config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const _getFetch = async (url: string) => {
  const res = await fetch(url, config);
  if (!res.ok) {
    const message = `An error has occured: ${res.status} - ${res.statusText}`;
    throw new Error(message);
  }
  return await res.json();
};

export const appLogout = () => {
  localStorage.clear();
  // console.log(process.env.REACT_APP_BASE_UI_URL + '/login');
  window.location.href = process.env.REACT_APP_BASE_UI_URL + '/login';
}


export const _concurrentRequest = async (requestList: any) => {
  try {
    const response = await axios.all(requestList);
    return response
  } catch (error) {
    throw error;
  }
}


const isPublic = (url: any) => {
  return url.includes('public');
}

axios.interceptors.request.use(
  config => {
    let tempconfig: any = JSON.parse(JSON.stringify(config))
    if (!tempconfig.hideLoader) MessageService.setLoading(true);
    if (isPublic(config.url as string)) {
      delete config.headers.Authorization;
      return config;
    }
    if (localStorage.getItem('token')) config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    else delete config.headers.Authorization;
    return config;
  },
  error => {
    console.log(error);
    MessageService.setLoading(false);
    return Promise.reject(error);
  }
);




const clearLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem(ACTION_CONSTANTS.USER);
  localStorage.removeItem(ACTION_CONSTANTS.USERID);
  localStorage.setItem('userType', 'user');
  localStorage.removeItem('refreshtoken');
}
const refreshToken = async function () {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('refreshtoken')}` },
    body: JSON.stringify({ "uid": localStorage.getItem('userid'), "credential": localStorage.getItem('token') })
  }
  const res = await fetch(`${process.env.REACT_APP_DONATION_ENDPOINT}/auth/refresh`, requestOptions);
  return await res.json();
}
const triggerRefreshToken = async function () {
  const newAccess = await refreshToken();
  if (newAccess?.token && newAccess?.refresh) {
    localStorage.setItem('token', newAccess.token)
    localStorage.setItem('refreshtoken', newAccess.refresh);
  } else {
    clearLocalStorage();
    MessageService.setStatus({ type: 'error', text: newAccess?.message });
    setTimeout(() => { window.location.href = process.env.PUBLIC_URL; })
  }
}

axios.interceptors.response.use(
  async (response) => {
    MessageService.setLoading(false);
    return response;
  },
  async (error) => {

    if (error.response) {
      if (error.response.status == 401) {
        MessageService.setStatus({ type: 'error', text: error.response.data.message });
        if (localStorage.getItem('token')) {
          MessageService.setStatus({ type: 'error', text: 'Session expired! Please login again.' });
          // triggerRefreshToken();
        }
        setTimeout(() => { appLogout(); }, 100);
      }
      if (error.response.status === 409) {
        MessageService.createTraderConnect(error.response.data.id);
      }
      if (error.response.status === 413) {
        MessageService.createTraderConnect(error.response.data.detail);
      }
      else {
        MessageService.setStatus({ type: 'error', text: error.response.data.message });
        MessageService.emitStageTransmissionError(error.response.data.message)
      }
      MessageService.setLoading(false);
      return new Promise(() => { });
    } else {
      MessageService.setStatus({ type: 'error', text: 'Unexpected Error' });
    }
  }
);